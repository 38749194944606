/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var mainMenu = function() {

          var $mainMenuContainer = $('#js-main-menu-container');
          var $mainMenuToggle = $('.js-main-menu-toggle');
          var $mainMenu = $('#js-main-menu');

          $mainMenu.attr('aria-expanded', 'false');

          var closeMenu = function() {
            $mainMenuContainer.removeClass('is-toggled');
            $mainMenuToggle.attr('aria-expanded', 'false').removeClass('is-active');
            $mainMenu.attr('aria-expanded', 'false');
            $mainMenuContainer.stop().fadeOut(200);
          };

          /* Toggle menu */
          $mainMenuToggle.on('click', function(e) {

            e.preventDefault();
            e.stopImmediatePropagation();

            // $mainMenuContainer.fadeToggle(250);

            if ($mainMenuContainer.is('.is-toggled')) {
              closeMenu();
            } else {
              $mainMenuContainer.addClass('is-toggled');
              $mainMenuToggle.attr('aria-expanded', 'true').addClass('is-active');
              $mainMenu.attr('aria-expanded', 'true');
              $mainMenuContainer.stop().fadeIn(200);
            }

          });

          /* Submenu */
          $('#js-main-menu .menu-item-has-children > a').on('click', function(e) {
            e.preventDefault();
            $this = $(this);
            $this.toggleClass('st-menu-open');
            // $header = $('#js-site-header');
            // $top = $header.outerHeight();
            if ($this.hasClass('st-menu-open')) {
              $this.siblings('.sub-menu').css({
                'display': 'flex',
                // 'top': $top
              }).hide().stop().slideDown(200);
            } else {
              $this.siblings('.sub-menu').stop().slideUp(200);
            }
          });

          /* Close menu when a click is made elsewhere on the page */
          $(document).on('click', function(e) {
            if (window.matchMedia("(min-width: 960px)").matches) {
              $('#js-main-menu .menu-item-has-children > a').each(function() {
                $this = $(this);
                $menu = $this.siblings('.sub-menu');
                menuArea = $(e.target).closest($menu).length;
                toggleArea = $(e.target).closest($this).length;
                if ($this.hasClass('st-menu-open')) {
                  if(!menuArea && !toggleArea) {
                    $this.removeClass('st-menu-open');
                    $this.siblings('.sub-menu').stop().slideUp(200);
                  }
                }
              });
            }
          });

        };

        mainMenu();

        if ($('#js-main-slider').length) {
          $('#js-main-slider').slick({
            slidesToShow: 1,
            // lazyLoad: 'progressive',
            prevArrow: $('#js-main-slider-btn-prev'),
            nextArrow: $('#js-main-slider-btn-next'),
            infinite: true,
            adaptiveHeight: true,
            // fade: true,
            rows: 0,
          });
        }

        var anchorLink = function() {
          $('.js-anchor-link, #js-landing-menu a').on('click', function(e) {
            e.preventDefault();
            var section = $(this).attr('href');
            var headerHeight = $('#js-site-header').outerHeight();
            var scrollDistance = $(section).offset().top - headerHeight;

            $('html, body').stop().animate({
              scrollTop: scrollDistance,
            }, 800);
          });
        };

        anchorLink();

        if ($('#js-d-modal').length) {

          var dynamicModal = function() {

            $('.js-open-d-modal').on('click', function(e) {
              e.preventDefault();
              var modalImg = $(this).attr('data-modal-img');
              var modalID = '#' + $(this).attr('data-modal-id');
              var modalContent = $(modalID).html();
              // $('#js-modal-img-wrapper').html('<img class="lazyload" data-src="' + modalImg + '" />');
              $('#js-modal-img-wrapper').html('<img src="' + modalImg + '" alt="" />');
              $('#js-modal-body').html(modalContent);
              $('#js-d-modal').css('display', 'flex').hide().fadeIn(200, function() {
                $('body').addClass('st-modal-open');
              });
            });

            $('.js-close-d-modal').on('click', function() {
              $('#js-d-modal').fadeOut(200, function() {
                $('body').removeClass('st-modal-open');
              });
            });

            /* Close menu when a click is made elsewhere on the page */
            $(document).on('click', function(e) {
              modalArea = $(e.target).closest('#js-d-modal-box').length;
              if ($('body').hasClass('st-modal-open') && $('#js-d-modal').is(":visible")) {
                if(!modalArea) {
                  $('#js-d-modal').fadeOut(200, function() {
                    $('body').removeClass('st-modal-open');
                  });
                }
              }
            });

          };

          dynamicModal();

        }

        /*
        var headerClass = function() {
          var top = $(document).scrollTop();
          // var distance = $('#js-scroll-distance').outerHeight();
          if (top > 50) {
            $('#js-site-header').addClass('st-scroll');
          } else {
            $('#js-site-header').removeClass('st-scroll');
          }
        };

        headerClass();

        $(document).on('scroll', function() {
          headerClass();
        });
        */

        $('#js-quote-slider').slick({
          infinite: true,
          prevArrow: '#js-quote-prev-btn',
          nextArrow: '#js-quote-next-btn',
          draggable: false,
          focusOnSelect: false,
          fade: true,
        });

        $('#js-post-slider').slick({
          infinite: true,
          prevArrow: '#js-slider-prev-btn',
          nextArrow: '#js-slider-next-btn',
          draggable: false,
          focusOnSelect: false,
          slidesToShow: 3,
          responsive: [
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 1,
                centerMode: true,
              }
            },
          ]
        });

        $.fancybox.defaults.animationEffect = "fade";
        $.fancybox.defaults.buttons = [
          "thumbs",
          "close"
        ];


        if ($('.js-modal').length) {

          var modal = function() {

            $('.js-open-modal').on('click', function(e) {
              e.preventDefault();
              var target = '#' + $(this).attr('data-modal');
              $(target).css('display', 'flex').hide().fadeIn(200, function() {
                $('body').addClass('st-modal-open');
              });
            });

            $('.js-close-modal').on('click', function() {
              $(this).closest('.js-modal').fadeOut(200, function() {
                $('body').removeClass('st-modal-open');
              });
            });

            /* Close menu when a click is made elsewhere on the page */
            $(document).on('click', function(e) {
              modalArea = $(e.target).closest('.js-modal-box').length;
              if ($('body').hasClass('st-modal-open')) {
                if(!modalArea) {
                  $('.js-modal').fadeOut(200, function() {
                    $('body').removeClass('st-modal-open');
                  });
                }
              }
            });

          };

          modal();

        }

        if ($('#js-booking-modal').length) {

          var bookingModal = function() {

            $('.js-open-booking-modal a').on('click', function(e) {
              e.preventDefault();
              var target = $(this).attr('href');
              $(target).css('display', 'flex').hide().fadeIn(200, function() {
                $('body').addClass('st-modal-open');
              });
            });

            $('#js-close-booking-modal').on('click', function() {
              $('#js-booking-modal').fadeOut(200, function() {
                $('body').removeClass('st-modal-open');
              });
            });

            /* Close menu when a click is made elsewhere on the page */
            $(document).on('click', function(e) {
              bookingModalArea = $(e.target).closest('#js-booking-modal-box').length;
              if ($('body').hasClass('st-modal-open') && $('#js-booking-modal').is(":visible")) {
                if(!bookingModalArea) {
                  $('#js-booking-modal').fadeOut(200, function() {
                    $('body').removeClass('st-modal-open');
                  });
                }
              }
            });

          };

          bookingModal();

        }

        if ($('#js-tour-slider').length) {

          $('#js-tour-slider').on('init reInit', function(event, slick, currentSlide, nextSlide){
            $('#js-counter-total').text(slick.slideCount);
          });

          $('#js-tour-slider').slick({
            slidesToShow: 1,
            // lazyLoad: 'progressive',
            prevArrow: $('#js-tour-slider-btn-prev'),
            nextArrow: $('#js-tour-slider-btn-next'),
            infinite: true,
            accessibility: false,
            draggable: false,
            adaptiveHeight: true,
            // initialSlide: 2,
            rows: 0,
          });

          $('#js-tour-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            $('.js-hotspot').removeClass('is-active');
            $('.js-hotspot').eq(nextSlide).addClass('is-active');
            $('#js-counter-current').text(nextSlide + 1);
          });

          $('.js-hotspot').on('click', function(e) {
            e.preventDefault();
            var index = $(this).index();
            $('#js-counter-current').text(index);
            $('.js-hotspot').removeClass('is-active');
            $(this).addClass('is-active');
            $('#js-tour-slider').slick('slickGoTo', index);
          });

        }

        if ($('#js-landing-menu').length) {

          var landingMenu = function() {
            var $menuContainer = $('#js-landing-menu-container');
            var $menuToggle = $('.js-landing-menu-toggle');
            var $menu = $('#js-landing-menu');
            $menu.attr('aria-expanded', 'false');

            var closeMenu = function() {
              $menuContainer.removeClass('is-toggled');
              $menuToggle.attr('aria-expanded', 'false').removeClass('is-active');
              $menu.attr('aria-expanded', 'false');
              $menuContainer.fadeOut(200);
            };

            /* Toggle menu */
            $menuToggle.on('click', function(e) {
              e.preventDefault();
              e.stopImmediatePropagation();
              // $menuContainer.fadeToggle(250);
              if ($menuContainer.is('.is-toggled')) {
                closeMenu();
              } else {
                $menuContainer.addClass('is-toggled');
                $menuToggle.attr('aria-expanded', 'true').addClass('is-active');
                $menu.attr('aria-expanded', 'true');
                $menuContainer.fadeIn(200);
              }
            });

            $(document).on('click', function(e) {
              menuArea = $(e.target).closest('#js-landing-menu-container').length;
              if ($menuContainer.is('.is-toggled')) {
                if(!menuArea) {
                  closeMenu();
                }
              }
            });
          };

          landingMenu();

        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('#js-news-slider').slick({
          infinite: true,
          prevArrow: '#js-news-prev-btn',
          nextArrow: '#js-news-next-btn',
          draggable: false,
          focusOnSelect: false,
          fade: true,
          adaptiveHeight: true,
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // About us page, note the change from about-us to about_us.
    'kuuljaks': {
      init: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
